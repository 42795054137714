import React, { type FunctionComponent } from 'react';
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Flex,
    Heading,
} from '@chakra-ui/react';
import { ContentContainer } from '../../components/ContentContainer';
import { storedSessionUser } from '../../keycloak';
import axios from 'axios';

export const GroundControl: FunctionComponent = () => {
    let allowButton = false;
    const allowsEmails = [
        'krisztina@outbankapp.com',
        'christine@outbankapp.com',
        'ralf@outbankapp.com',
        'hans@outbankapp.com',
        'christians@outbankapp.com',
    ];

    if (storedSessionUser && allowsEmails.includes(storedSessionUser.email)) {
        allowButton = true;
    }

    return (
        <ContentContainer headline="Ground Control" description="">
            <Accordion
                mt={20}
                borderLeft="1px solid"
                borderRight="1px solid"
                borderColor="gray.400"
                allowMultiple={true}
                index={[0, 1, 2]}
            >
                <AccordionItem>
                    <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                            <Heading>Production</Heading>
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        <Flex flexDirection="row">
                            <Box flex={1}>Push latest staging changes to production</Box>
                            {allowButton && (
                                <Button
                                    colorScheme="blue"
                                    onClick={async () => {
                                        await axios.post(
                                            `${process.env.REACT_APP_JENKINS_URL}/buildByToken/build?job=GC4-Staging-Promotion&token=${process.env.REACT_APP_JENKINS_TOKEN}`,
                                        );
                                    }}
                                >
                                    Push
                                </Button>
                            )}
                        </Flex>
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                            <Heading>Staging</Heading>
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        <Flex flexDirection="row">
                            <Box flex={1}>
                                Push latest webconnect (master) changes to staging (CAUTION: This potentially pushes
                                existing configuration changes.)
                            </Box>
                            {allowButton && (
                                <Button
                                    colorScheme="blue"
                                    onClick={async (): Promise<void> => {
                                        await axios.post(
                                            `${process.env.REACT_APP_JENKINS_URL}/buildByToken/build?job=GC4-Webconnect-Promotion&token=${process.env.REACT_APP_JENKINS_TOKEN}`,
                                        );
                                    }}
                                >
                                    Push
                                </Button>
                            )}
                        </Flex>
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                            <Heading>Localization</Heading>
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        <Flex flexDirection="row">
                            <Box flex={1}>
                                Push and pull latest Localization changes for Kernel-only (wait for message in
                                Teams#Translations before pushing to staging).
                            </Box>
                            {allowButton && (
                                <Button
                                    colorScheme="blue"
                                    onClick={async (): Promise<void> => {
                                        await axios.post(
                                            `${process.env.REACT_APP_JENKINS_URL}/buildByToken/buildWithParameters?job=OutbankLocalization&Localization_Project=ServicePortal&token=${process.env.REACT_APP_JENKINS_TOKEN}`,
                                        );
                                    }}
                                >
                                    Push
                                </Button>
                            )}
                        </Flex>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </ContentContainer>
    );
};
